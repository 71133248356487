<template>
    <div class="comp">
        <div class="left_tit">
            <div class="gong_name">
                <div class="borderDiv"></div>
                <div class="titDiv">
                    <p class="name_p">合作企业</p>
                    <p class="eng_p">COOPERATIVE ENTERPRISE</p>
                </div>
            </div>
            <div class="ints">
                <div class="jieshao">
                    目前合作企业遍布陕西各个市区，均为陕西省内有影响力的托管企业，合作数量已超100家，企业所含老师、学员、运营数量已超10000人，为帮助广大托管企业实现高效管理，现继续扩大合作规模，有意向的企业可随时联系合作相关事宜
                </div>
                <div class="boe_red"></div>
            </div>
            <div class="englis">
                <div class="eng_jie">
                    At present, the cooperative enterprises are located in all urban areas of Shaanxi Province. They are
                    all influential trusteeship enterprises in Shaanxi Province. The number of cooperative enterprises
                    has exceeded 100, and the number of teachers, students and operators of the enterprises has exceeded
                    10000. In order to help the trusteeship enterprises achieve efficient management, we continue to
                    expand the scale of cooperation. Interested enterprises can contact the cooperation related matters
                    at any time
                </div>
                <div class="cric">
                    <img src="../../assets/imgs/icon.png" alt="">
                </div>
            </div>
        </div>
        <div class="right_img">
            <div class="img_diuv">
                <div class="imgkd1">
                    <img src="../../assets/imgs/qy1.png" alt="">
                </div>
                <div class="imgkd2">
                    <img src="../../assets/imgs/qy2.png" alt="">
                </div>
                <div class="imgkd3">
                    <img src="../../assets/imgs/qy3.png" alt="">
                </div>
                <div class="imgkd4">
                    <img src="../../assets/imgs/qy4.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.comp {
    width: 100%;
    // height: 50%;
    display: flex;
    justify-content: space-between;
    .left_tit {
        width: 38%;
        height: 100%;
        padding: 0 70px 30px 200px;
        // background: red;
        .gong_name {
            position: relative;
            width: 266px;
            height: 108px;
            margin-top: 74px;
            .borderDiv {
                width: 208px;
                height: 108px;
                border-radius: 4px;
                border: 10px solid #f0f0f0;
                position: absolute;
                left: 60px;
                top: -30px;
                z-index: -1;
            }
            .titDiv {
                text-align: left;
                .name_p {
                    color: #2e2133;
                    font-size: 36px;
                    font-weight: 600;
                }
                .eng_p {
                    color: #5c6366;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .ints {
            width: 512px;
            text-align: left;
            line-height: 26px;
            margin-top: 50px;
            font-size: 16px;
            font-weight: normal;
            color: #454a4d;
            .boe_red {
                width: 100px;
                height: 3px;
                background: #f30b0b;
                margin-top: 15px;
            }
        }
        .englis {
            width: 512px;
            text-align: left;
            line-height: 26px;
            margin-top: 30px;
            font-size: 16px;
            font-weight: normal;
            color: #8a9499;
            .cric {
                width: 100px;
                height: 20px;
                margin-top: 10px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .right_img {
        width: 26%;
        height: 78%;
        padding: 120px 200px 0 0;
        .img_diuv {
            width: 360px;
            height: 340px;
            display: flex;
            flex-wrap: wrap;
            .imgkd1 {
                width: 172px;
                height: 162px;
                margin: 0 4px 4px 0;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .imgkd2 {
                width: 172px;
                height: 162px;
                margin: 0 0 4px 4px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .imgkd3 {
                width: 172px;
                height: 162px;
                margin: 4px 4px 0 0;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .imgkd4 {
                width: 172px;
                height: 162px;
                margin: 4px 0 0 4px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>